/* ### general ### */
.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-red-400 {
  color: #ff6259 !important;
}

.text-red-500 {
  color: #ff3d32 !important;
}

.text-red-500 {
  color: #d9342b !important;
}

.text-form-label {
  color: rgba(0, 0, 0, 0.6) !important;
}

.entity-view-subtitle {
  font-size: 16px;
  font-weight: bold;
  color: #1565C0;
}

/* ### login ### */
.login-page .card {
  min-width: 360px;
}

.login-page .card form {
  margin-top: 1rem;
}

.login-page .card .field {
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 640px) and (max-width: 959.98px) {
  .login-page .card {
    width: 100%;
  }
}

@media screen and (max-width: 639.98px) {
  .login-page .card {
    width: 100%;
  }
}

.layout-topbar-action-item .svg-inline--fa {
  width: 14px;
  height: auto;
}

/* overwrite */
.layout-topbar {
  height: 80px;
}

.menu-wrapper {
  height: calc(100% - 80px);
  top: 80px;
}

.layout-wrapper .layout-main {
  padding-top: 80px;
}

@media (max-width: 991px) {
  .layout-topbar {
    height: auto;
  }
  .layout-topbar .layout-topbar-left {
    height: 80px;
  }
  .layout-wrapper .menu-wrapper {
    height: calc(100% - 80px);
    top: 80px;
  }  

  .layout-topbar .layout-topbar-right .layout-topbar-actions-right .layout-topbar-items {
    justify-content: end;
  }
}

.layout-wrapper .layout-main .layout-content {
  padding-bottom: 0.5rem;
}

.layout-topbar-blue .layout-topbar .layout-topbar-left {
  background-color: #004990;
}

.pages-body.login-page .pages-panel .pages-header {
  background: #004990;
}

.p-dialog .p-dialog-content {
  padding-top: 1rem;
}

@media screen and (max-width: 639.98px) {
  .layout-wrapper .layout-main .layout-content {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.p-dropdown {
  border-radius: 4px !important;
}

.p-autocomplete input {
  border-right-width: 0px;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.p-autocomplete .p-inputtext:enabled:focus,
.p-autocomplete .p-inputtext:active {
  border-right-width: 0px !important;
}

.p-autocomplete .p-autocomplete-dropdown {
  background-color: transparent ;
  color: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-left: 0px;
}

.p-autocomplete.p-inputwrapper-focus .p-autocomplete-dropdown {
  border-color: rgb(61, 79, 176, 0.957) !important;
  box-shadow: inset 0 0 0 1px #3F51B5, inset 0 0 0 1px #3F51B5, inset 0 0 0 1px #3F51B5, inset 0 0 0 1px #3F51B5;
}

.p-autocomplete:hover .p-autocomplete-dropdown {
  border-color: rgba(0, 0, 0, 0.87) !important;
}

.p-autocomplete .p-autocomplete-dropdown:hover {
  background-color: transparent ;
  color: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.38) !important;
  border-left: 0px !important;
}

.p-autocomplete.p-invalid .p-autocomplete-dropdown {
  border-color: rgb(176, 0, 32) !important;
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0;
}

.p-breadcrumb .p-menuitem-link {
  padding: 0.25rem 0.25rem;
}

.pcn-accordion-language-level.p-accordion .p-accordion-tab {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.38) !important;
}

.pcn-accordion-language-level.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 12px 10px;
}

.pcn-accordion-language-level.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
.pcn-accordion-language-level.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
.pcn-accordion-language-level.p-accordion .p-accordion-tab:not(.p-accordion-tab-active) .p-accordion-header-link:focus {
  background: #FFFFFF !important;
}

.pcn-table-filer.p-accordion .p-accordion-tab {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.38) !important;
}

.pcn-table-filer.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 12px 10px;
}

.pcn-table-filer.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
.pcn-table-filer.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
.pcn-table-filer.p-accordion .p-accordion-tab:not(.p-accordion-tab-active) .p-accordion-header-link:focus {
  background: #FFFFFF !important;
}

.bold {
  font-weight: 700;
}

.status {
  border-radius: var(--border-radius);
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.status-active {
    background: #C8E6C9;
    color: #256029;
}

.status-inactive,
.status-archived {
    background: #FFCDD2;
    color: #C63737;
}

.status-draft {
    background: #8fabfa;
    color: #1505ec;
}

.status-published {
  background: #C8E6C9;
  color: #256029;
}

.status-finished {
  background: #1565C0;
  color: #FFFFFF;
}

/* competition statuses */
.status-competition-draft {
  background: #8fabfa;
  color: #1505ec;
}

.status-competition-active,
.status-competition-registration_open {
    background: #C8E6C9;
    color: #256029;
}

.status-competition-eligibility,
.status-competition-eligibility_contestation_open,
.status-competition-eligibility_contestation_validation {
    background: #8BC24A;
    color: #256029;
}

.status-competition-preliminary_test,
.status-competition-preliminary_test_contestation_open,
.status-competition-preliminary_test_contestation_validation {
    background: #4CAF50;
    color: #FFFFFF;
}

.status-competition-advanced_test,
.status-competition-advanced_test_contestation_open,
.status-competition-advanced_test_contestation_validation {
    background: #009688;
    color: #FFFFFF;
}

.status-competition-job_supplementary_test_1,
.status-competition-job_supplementary_test_1_contestation_open,
.status-competition-job_supplementary_test_1_contestation_validation {
    background: #38803a;
    color: #FFFFFF;
}

.status-competition-job_supplementary_test_2,
.status-competition-job_supplementary_test_2_contestation_open,
.status-competition-job_supplementary_test_2_contestation_validation {
    background: #208522;
    color: #FFFFFF;
}

.status-competition-job_supplementary_test_3,
.status-competition-job_supplementary_test_3_contestation_open,
.status-competition-job_supplementary_test_3_contestation_validation {
    background: #018606;
    color: #FFFFFF;
}

.status-competition-job_written_test,
.status-competition-job_written_test_contestation_open,
.status-competition-job_written_test_contestation_validation {
    background: #2a542c;
    color: #FFFFFF;
}

.status-competition-job_interview,
.status-competition-job_interview_contestation_open,
.status-competition-job_interview_contestation_validation {
    background: #0e5510;
    color: #FFFFFF;
}

.status-competition-finished {
  background: #1565C0;
  color: #FFFFFF;
}

/* application statuses */
.status-application-registered,
.status-application-applied,
.status-application-submitted {
  background: #1565C0;
  color: #FFFFFF;
}

.status-application-in_validation {
  background: #8fabfa;
  color: #1505ec;
}

.status-application-accepted {
    background: #C8E6C9;
    color: #256029;
}

.status-application-rejected {
    background: #FFCDD2;
    color: #C63737;
}

.status-application-indecisive {
  background: #fff2e2;
  color: #cc8925;
}

.status-application-contestation,
.status-application-contestation_validation {
  background: #6A1B9A;
  color: #FFFFFF;
}

/* evaluation statuses */
.status-evaluation-in_progress {
  background: #8fabfa;
  color: #1505ec;
}

.status-evaluation-accepted {
    background: #C8E6C9;
    color: #256029;
}

.status-evaluation-rejected {
    background: #FFCDD2;
    color: #C63737;
}

.status-evaluation-clarification {
  background: #fff2e2;
  color: #cc8925;
}

/* field evaluation statuses */
.status-field-evaluation-not_evaluated {
  background: #8fabfa;
  color: #1505ec;
}

.status-field-evaluation-accepted {
    background: #C8E6C9;
    color: #256029;
}

.status-field-evaluation-rejected {
    background: #FFCDD2;
    color: #C63737;
}

.status-field-evaluation-clarification {
  background: #fff2e2;
  color: #cc8925;
}

/* result statuses */
.status-result-accepted {
  background: #C8E6C9;
  color: #256029;
}

.status-result-rejected {
  background: #FFCDD2;
  color: #C63737;
}

/* result types */
.status-result-type-eligibility,
.status-result-type-eligibility_contestation,
.status-result-type-eligibility_final {
    background: #8BC24A;
    color: #256029;
}

.status-result-type-preliminary_test,
.status-result-type-preliminary_test_contestation,
.status-result-type-preliminary_test_final {
    background: #4CAF50;
    color: #FFFFFF;
}

.status-result-type-advanced_test,
.status-result-type-advanced_test_contestation,
.status-result-type-advanced_test_final {
    background: #009688;
    color: #FFFFFF;
}

.status-result-type-job,
.status-result-type-job_contestation,
.status-result-type-job_contestation_final {
    background: #38803a;
    color: #FFFFFF;
}

.status-result-type-competition_final {
  background: #1565C0;
  color: #FFFFFF;
}

/* application test session status */
.status-applicationTestSession-present {
  background: #C8E6C9;
  color: #256029;
}

.status-applicationTestSession-absent {
  background: #FFCDD2;
  color: #C63737;
}

/* clarification statuses */
.clarification-status-sent,
.contestation-status-sent {
  background: #C8E6C9;
  color: #256029;
}

.clarification-status-not-sent,
.contestation-status-not-sent {
  background: #FFCDD2;
  color: #C63737;
}

.contestation-status-not-filled {
  background: #fff2e2;
  color: #cc8925;
}

.pill {
  border-radius: var(--border-radius);
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.competition-type-national_debutant {
  background: rgb(0, 188, 212);
  color: rgb(26, 35, 126);
}

.competition-type-national_high {
  background: rgb(43, 150, 253);
  color: rgb(26, 35, 126);
}

.competition-type-job_debutant {
  background: rgb(72, 215, 1);
  color: rgb(0, 96, 100);
}

.competition-type-job_high {
  background: rgb(188, 252, 114);
  color: rgb(0, 96, 100);
}

.pcn-dialog {
  width: 70%;
}
.pcn-dialog-big {
  width: 90%;
}

@media screen and (min-width: 640px) and (max-width: 991.98px) {
  .pcn-dialog {
    width: 90%;
  }
  .pcn-dialog-big {
    width: 100%;
  }
}

@media screen and (max-width: 639.98px) {
  .pcn-dialog, .pcn-dialog-big {
    width: 100%;
  }
}

.pcn-sun-editor .sun-editor .se-container .se-toolbar .se-btn-tray,
.pcn-sun-editor .sun-editor .se-container .se-toolbar .se-menu-tray {
  width: 92% !important;
}

.pcn-html-editor-view-container {
  min-height: 250px;
}

.pcn-panel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pcn-captcha{
  width: 100%;
  max-width: 329px;
}

.pcn-dialog-subtitle {
  font-size: 15px;
  font-weight: 400;
  line-height: 17px;
}

.pcn-warning-color {
  color: #ff9800;
}

.pcn-data-view-panel .filed-label {
  font-weight: 700;
  margin-bottom: 3px;
}

.pcn-data-view-panel .filed-value {
  font-size: 16px;
}

.p-button.p-button-pcn-entity-action {
  padding: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 2px;
}

.pcn-selectbutton-slim .p-button {
  padding: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 2px;  
  border: 0;
  border-radius: 0;
  color: #3F51B5;
}

.pcn-selectbutton-slim.p-selectbutton .p-button.p-highlight {
  background: #e9ecf8;
  border-color: #F7F8FC;
  color: #3F51B5;
}
.pcn-selectbutton-slim.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecf8;
  border-color: #F7F8FC;
  color: #3F51B5;
}
.pcn-selectbutton-slim.p-selectbutton .p-button.p-highlight:hover {
  background: #e9ecf8;
  border-color: #F7F8FC;
  color: #3F51B5;
}
.pcn-selectbutton-slim.p-selectbutton .p-button:focus {
  background: #e9ecf8;
  border-color: #F7F8FC;
}
.pcn-selectbutton-slim.p-selectbutton .p-button:focus.p-highlight {
  background: #e9ecf8;
  border-color: #F7F8FC;
}

.pcn-button-slim.p-button {
  padding: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 2px;  
}

.aspc-button-icon-slim.p-button {
  width: auto;
  padding-left: 2px;
  padding-right: 2px;
}

.aspc-button-filed-text.p-button {
  font-size: 16px;
  padding: 0;
}

.p-button.p-button-pcn-entity-action.selected {
  color: #f7f7f7;
  background: #42526E !important;
}
.p-button.p-button-pcn-entity-action, .p-buttonset.p-button-pcn-entity-action > .p-button, .p-splitbutton.p-button-pcn-entity-action > .p-button, .p-fileupload-choose.p-button-pcn-entity-action {
  color: #42526E;
  background: #f7f7f7 !important;
  border: 0 none;
}
.p-button.p-button-pcn-entity-action:enabled:hover, .p-button.p-button-pcn-entity-action:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-pcn-entity-action > .p-button:enabled:hover, .p-buttonset.p-button-pcn-entity-action > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-pcn-entity-action > .p-button:enabled:hover, .p-splitbutton.p-button-pcn-entity-action > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-pcn-entity-action:enabled:hover, .p-fileupload-choose.p-button-pcn-entity-action:not(button):not(a):not(.p-disabled):hover {
  background: rgba(247, 247, 247, 0.92) !important;
  color: #42526E;
  border-color: transparent;
}
.p-button.p-button-pcn-entity-action:enabled:focus, .p-button.p-button-pcn-entity-action:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-pcn-entity-action > .p-button:enabled:focus, .p-buttonset.p-button-pcn-entity-action > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-pcn-entity-action > .p-button:enabled:focus, .p-splitbutton.p-button-pcn-entity-action > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-pcn-entity-action:enabled:focus, .p-fileupload-choose.p-button-pcn-entity-action:not(button):not(a):not(.p-disabled):focus {
  box-shadow: none;
}
.p-button.p-button-pcn-entity-action:enabled:active, .p-button.p-button-pcn-entity-action:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-pcn-entity-action > .p-button:enabled:active, .p-buttonset.p-button-pcn-entity-action > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-pcn-entity-action > .p-button:enabled:active, .p-splitbutton.p-button-pcn-entity-action > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-pcn-entity-action:enabled:active, .p-fileupload-choose.p-button-pcn-entity-action:not(button):not(a):not(.p-disabled):active {
  background: rgba(247, 247, 247, 0.68) !important;
  color: #42526E;
  border-color: transparent;
}
.p-button.p-button-pcn-entity-action.p-button-outlined, .p-buttonset.p-button-pcn-entity-action > .p-button.p-button-outlined, .p-splitbutton.p-button-pcn-entity-action > .p-button.p-button-outlined, .p-fileupload-choose.p-button-pcn-entity-action.p-button-outlined {
  background-color: transparent !important;
  color: #f7f7f7;
  border: 1px solid;
}
.p-button.p-button-pcn-entity-action.p-button-outlined:enabled:hover, .p-button.p-button-pcn-entity-action.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-pcn-entity-action > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-pcn-entity-action > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-pcn-entity-action > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-pcn-entity-action > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-pcn-entity-action.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-pcn-entity-action.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: rgba(247, 247, 247, 0.04) !important;
  color: #f7f7f7;
  border: 1px solid;
}
.p-button.p-button-pcn-entity-action.p-button-outlined:enabled:active, .p-button.p-button-pcn-entity-action.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-pcn-entity-action > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-pcn-entity-action > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-pcn-entity-action > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-pcn-entity-action > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-pcn-entity-action.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-pcn-entity-action.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: rgba(247, 247, 247, 0.16) !important;
  color: #f7f7f7;
  border: 1px solid;
}
.p-button.p-button-pcn-entity-action.p-button-text, .p-buttonset.p-button-pcn-entity-action > .p-button.p-button-text, .p-splitbutton.p-button-pcn-entity-action > .p-button.p-button-text, .p-fileupload-choose.p-button-pcn-entity-action.p-button-text {
  background-color: transparent !important;
  color: #f7f7f7;
  border-color: transparent;
}
.p-button.p-button-pcn-entity-action.p-button-text:enabled:hover, .p-button.p-button-pcn-entity-action.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-pcn-entity-action > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-pcn-entity-action > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-pcn-entity-action > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-pcn-entity-action > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-pcn-entity-action.p-button-text:enabled:hover, .p-fileupload-choose.p-button-pcn-entity-action.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: rgba(247, 247, 247, 0.04) !important;
  border-color: transparent;
  color: #f7f7f7;
}
.p-button.p-button-pcn-entity-action.p-button-text:enabled:active, .p-button.p-button-pcn-entity-action.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-pcn-entity-action > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-pcn-entity-action > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-pcn-entity-action > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-pcn-entity-action > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-pcn-entity-action.p-button-text:enabled:active, .p-fileupload-choose.p-button-pcn-entity-action.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgba(247, 247, 247, 0.16) !important;
  border-color: transparent;
  color: #f7f7f7;
}

.pcn-status-toogle.p-togglebutton.p-button.p-highlight,
.pcn-status-toogle.p-togglebutton.p-button:focus.p-highlight,
.pcn-status-toogle.p-togglebutton.p-button.p-highlight .p-button-icon-left {
  background: #C8E6C9;
  border-color: #C8E6C9;
  color: #256029;  
}
.pcn-status-toogle.p-togglebutton.p-button:not(.p-disabled):hover.p-highlight,
.pcn-status-toogle.p-togglebutton.p-button:not(.p-disabled):hover.p-highlight .p-button-icon-left {
  background: #C8E6C9;
  border-color: #C8E6C9;
  color: #256029;
}
.pcn-status-toogle.p-togglebutton.p-button,
.pcn-status-toogle.p-togglebutton.p-button:focus,
.pcn-status-toogle.p-togglebutton.p-button .p-button-icon-left {
  background: #FFCDD2;
  border-color: #FFCDD2;
  color: #C63737;
}
.pcn-status-toogle.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover,
.pcn-status-toogle.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left {
  background: #FFCDD2;
  border-color: #FFCDD2;
  color: #C63737;
}

.pcn-datatable .p-datatable-header {
  padding-left: 0;
  padding-right: 0;
}

.pcn-form-field-view {
  border: 1px solid #e7e7e7;
  padding: 10px;
}

.pcn-form-field-view.no-border {
  border: 0px;
}

.menu-wrapper .layout-menu-container .layout-menu li.layout-root-menuitem > div {
  margin-top: 1rem;
  margin-bottom: 0.2rem;
}

.menu-wrapper .layout-menu-container .layout-menu li > a, .menu-wrapper .layout-menu-container .layout-menu li > button {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.p-autocomplete:not(.p-invalid) .p-autocomplete-dropdown.p-button:disabled {
  background-color: #FFFFFF !important;
  border-color: rgba(118, 118, 118, 0.3) !important;
}

.p-autocomplete.p-invalid .p-autocomplete-dropdown.p-button:disabled {
  background-color: #FFFFFF !important;
  border-color: rgb(176, 0, 32) !important;
  opacity: 0.38;
}

.layout-wrapper .layout-main .layout-content {
  padding: 1rem 10px;
}

.pcn-confirm-dialog {
  max-width: 450px;
}

.pcn-confirm-dialog.no-icon .p-dialog-content .p-confirm-dialog-message {
  margin: 0;
}

.layout-config-button.p-button {
  top: max(10%, 90px);
}
@media screen and (max-width: 639.98px) {
  .layout-config-button.p-button {
    top: max(20%, 90px);
  }
}

.p-button-icon-only, .p-button-icon-only .p-button-icon {
  font-size: 18px;
}

@media screen and (max-height: 899.98px) {
  .pcn-register {
    height: auto !important;
  }
}

@media screen and (max-height: 899.00px) {
  .pcn-register-form {
    margin-top: 40px !important;
    margin-bottom: 10px !important;
    min-height: 667px;
  }  
}

.pcn-register-container .p-inputgroup {
  margin-top: 1rem;
  margin-bottom: 0px !important;
}
.pcn-register-container .field-checkbox {
  margin-top: 1rem;
  margin-bottom: 0.5rem !important;
}
@media screen and (max-height: 749.98px) {
  .pcn-register-container .p-inputgroup {
    margin-top: 0.7rem;
  }
  .pcn-register-container .field-checkbox {
    margin-top: 0.7rem;
    margin-bottom: 5px !important;
  }  

  input.pcn-register-inputtext,
  .pcn-register-inputtext input {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

.p-overlay-badge.pcn-notification-badge .p-badge {
  right: 7px;
}

.pages-body.before-login-pages .pcn-login-logos {
  background-color: #FFFFFF !important;
  background: url("/public/assets/layout/images/pages/eu-logo-big.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 80px;
}

@media screen and (max-width: 1200.00px) {
  .pages-body.before-login-pages .pcn-login-logos {
    background: url("/public/assets/layout/images/pages/eu-logo.png");
    background-repeat: no-repeat;
    background-position: center;
    height: 80px;
  }
}

@media screen and (max-width: 900.00px) {
  .pages-body.before-login-pages .pcn-login-logos {
    background: url("/public/assets/layout/images/pages/eu-logo-small.png");
    background-repeat: no-repeat;
    background-position: center;
    height: 80px;
  }
}

@media screen and (max-width: 567.00px) {
  .pages-body.before-login-pages .pcn-login-logos {
    background: url("/public/assets/layout/images/pages/eu-logo-small.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    max-height: 73px;
  }
}

.pcn-datatable .p-component-overlay {
  background-color: rgba(0, 0, 0, 0.05);
  transition-duration: 0.2s;
}

.pcn-datatable .p-component-overlay .p-datatable-loading-icon {
  color: #256029;
  font-size: 60px;
}


.pcn-inplace.p-inplace .p-inplace-display {
    padding: 0;
    transition: none;
}

.pcn-inplace.p-inplace .p-inplace-display.p-disabled {
  opacity: 1;
}

.pcn-inplace .p-dropdown {
  height: 30px;
}

.pcn-inplace .p-dropdown-label.p-inputtext {
  padding: 5px;
}

.pcn-inplace .p-inplace-content-close.p-button-icon-only {
  height: 30px;
  width: 30px;
  background-color: transparent;
  color: #212121;
}

.pcn-splitbutton .p-button-icon-only {
  font-size: 14px;
}

.notificationContent a:link {
  color: blue;
  background-color: transparent;
  text-decoration: none;
}

.notificationContent a:visited {
  color: dodgerBlue;
  background-color: transparent;
  text-decoration: none;
}

.notificationContent a:hover {
  color: red;
  background-color: transparent;
  text-decoration: underline;
}

.notificationContent a:active {
  color: lime;
  background-color: transparent;
  text-decoration: underline;
}

.aspc-datatable-small-font {
  font-size: 13px;
}